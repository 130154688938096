import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation adminLogin($data: loginInput!) {
    adminLogin(data: $data) {
      authToken
      user {
        id
        firstName
        lastName
        email
        phoneNo
        uuid
        roles
        profileImage {
          url
          key
          name
          extension
          contentType
        }
        isActive
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation adminForgotPassword($email: EmailAddress!) {
    adminForgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: resetPasswordInput!) {
    resetPassword(data: $data) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation verifyToken($data: verifyTokenInput!) {
    verifyToken(data: $data) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser($authToken: String) {
    logoutUser(data: { authToken: $authToken }) {
      status
      message
    }
  }
`;
